@media screen and (max-width: 700px) {
  nav.navbar {
    display: none;
    color: green;
  }

  nav.navbar-short {
    z-index: 100;
    font-family: raleway-light;
    color: white;
    display: flex;
    flex-direction: column;
  }

  div.nav-link {
    font-size: 15px;
  }

  Link.navshort-link {
    font-size: 15px;
  }
}

.navbar {
  z-index: 100;
  font-family: raleway-light;
  color: white;
}

.navbar-short {
  display: none;
}

.navlinks-row {
  display: flex;
  flex-direction: column;
}


.first-link {
  color: white;
  font-size: 20px;
  margin: 0.5em;
  text-decoration: none;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.8em;
  cursor: pointer;
  float: left;
  transition: 0.5s ease;
}

.nav-link {
  color: white;
  float: right;
  font-size: 20px;
  margin: 0.5em;
  text-decoration: none;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.8em;
  cursor: pointer;
  transition: 0.5s ease;
}

.short-first-link {
  color: white;
  font-size: 20px;
  margin: 0.5em;
  text-decoration: none;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.8em;
  cursor: pointer;
  float: left;
  transition: 0.5s ease;
}

.navshort-link {
  color: white;
  float: right;
  font-size: 20px;
  margin: 0.5em;
  text-decoration: none;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.8em;
  cursor: pointer;
  transition: 0.5s ease;
}

.nav-link:hover {
  box-shadow: inset 0 -2px 0 0 white;
}

.first-link:hover {
  box-shadow: inset 0 -2px 0 0 white;
}

