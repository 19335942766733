@media screen and (max-width: 800px) {
  div.footer-wrapper {
    font-size: 13px;
  }

  a.social-link {
    padding: 3%;
  }
}

.footer-wrapper {
  padding: 5%;
  text-align: center;
  font-family: raleway-medium;
}

.social-row {
}

.social-icon {
  padding: 1%;
}

.social-icon:hover {
  color: rgb(68, 146, 178);
}

.social-link {
  color: black;
}